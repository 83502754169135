import React from 'react'
import PropTypes from 'prop-types'
import MaskedInput, { conformToMask } from 'react-text-mask';

export default function CnpjInput({ inputRef, onChange, value, ...props }) {
    const [inputValue, setInputValue] = React.useState(value)

    return (
        <MaskedInput
            {...props}
            value={inputValue}
            onChange={function (value) {
                setInputValue(value.target.value)
                const { conformedValue } = conformToMask(
                    value.target.value,
                    [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
                    { guide: false }
                )
                onChange(conformedValue)
            }}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={[/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
            guide={false}
        />
    )
}

CnpjInput.propTypes = {
    inputRef: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired
};