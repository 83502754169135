import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import swal from 'sweetalert';
import api from '../services/api';
import { useHistory, useLocation } from 'react-router-dom';


import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import Logo from '../assets/logo.svg';
import Loading from '../components/Loading';


const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  logo: {
    width: 250,
  }
}));


const ResetPassword: React.FC = () => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);

  const validationSchema = yup.object({
    password: yup
      .string()
      .required('Senha obrigatória'),
    password_confirmation: yup
      .string().oneOf(
        [yup.ref('password'), null],
        'Confirmação incorreta',
      ),
  });

  const location = useLocation();
  const history = useHistory();

  const formik = useFormik({
    initialValues: {
      password: '',
      password_confirmation: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        if (!isLoading) {
          setIsLoading(true);
          
          const token = location.search.replace('?codigoRecuperacao=', '');
          if (!token) {
            swal({
              title: "Erro ao resetar",
              text: "Ocorreu um erro ao resetar a senha, tente novamente",
              icon: "error",
            });
            return;
          }

          await api.post('/recuperarSenha', {
            senha: values.password,
            confirmarSenha: values.password_confirmation,
            codigoRecuperacao: token,
          });

          history.push('/');

        }
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);

        if (e.response) {
          if (e.response.status === 400) {
            swal({
              title: "Erro ao resetar senha",
              text: e.response.data,
              icon: "error",
            });
          }
        } else {
          swal({
            title: "Ocorreu um erro",
            text: "Ocorreu um erro no servidor, tente mais tarde",
            icon: "error",
          });
        }
      }
    },
  });

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <img src={Logo} alt="Valle" className={classes.logo} />
        <Typography component="h1" variant="h5">
          Resetar senha
        </Typography>

        <form className={classes.form} onSubmit={formik.handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="password"
            label="Senha"
            type="password"
            name="password"
            autoFocus
            value={formik.values.password}
            onChange={formik.handleChange}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
          />

          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password_confirmation"
            label="Confirmar senha"
            type="password"
            id="password_confirmation"
            value={formik.values.password_confirmation}
            onChange={formik.handleChange}
            error={formik.touched.password_confirmation && Boolean(formik.errors.password_confirmation)}
            helperText={formik.touched.password_confirmation && formik.errors.password_confirmation}
          />
          <Button
            startIcon={<Loading show={isLoading} />}
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Alterar senha
          </Button>
        </form>
      </div>
    </Container>
  );
}

export default ResetPassword;