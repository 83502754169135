import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import api from '../../../services/api';
import swal from 'sweetalert';

import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';

import Navigation from '../../../components/Navigation';
import Loading from '../../../components/Loading';
import CpfInput from '../../../components/CpfInput';
import PhoneInput from '../../../components/PhoneInput';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: 35,
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',

  },
  fixedHeight: {
    height: 240,
  },
  form: {
    marginTop: 25,
  }
}));

interface PevProps {
  id: number;
  nome: string;
}

const RegisterEmployee: React.FC = () => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [pev, setPev] = useState<PevProps[]>([]);
  const history = useHistory();

  const fixedHeightPaper = clsx(classes.paper);

  useEffect(() => {
    api
      .get("/pev")
      .then((response) => {
        setPev(response.data);
      })
      .catch((error) => {
        swal({
          title: "Ocorreu um erro ao buscar as PEVs",
          icon: "error",
        });
      });
  }, []);

  const validationSchema = yup.object({
    name: yup
      .string()
      .required('Nome obrigatório'),
    email: yup
      .string()
      .email('Digite um e-mail válido'),
    cpf: yup
      .string()
      .required('CPF obrigatório')
      .min(11, "Digite um CPF válido")
      .max(11, "Digite um CPF válido"),
    phone: yup
      .string()
      .required('Telefone obrigatório')
      .min(10, "Digite um celular válido")
      .max(11, "Digite um celular válido"),
    user: yup
      .string()
      .required('Endereço obrigatório'),
    address: yup
      .string()
      .required('Endereço obrigatório'),
    password: yup
      .string()
      .required('Senha obrigatória'),
    password_confirmation: yup
      .string().oneOf(
        [yup.ref('password'), null],
        'Confirmação incorreta',
      ),
    registrationNumber: yup
      .string(),
    idPev: yup
        .number()
        .required('Selecione uma PEV'),
  });

  const formik = useFormik({
    initialValues: {
      password: '',
      password_confirmation: '',
      name: '',
      user: '',
      email: '',
      phone: '',
      address: '',
      idPev: '',
      registrationNumber: '',
      cpf: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        if (!isLoading) {
          setIsLoading(true);

          await api.post('/usuario', {
            senha: values.password,
            confirmarSenha: values.password_confirmation,
            nome: values.name,
            tipoUsuario: '4',
            user: values.user,
            email: values.email,
            telefone: values.phone,
            endereco: values.address,
            idPev: values.idPev,
            matricula: values.registrationNumber,
            cnpj: null,
            cpf: values.cpf,
          });

          swal({
            title: "Cadastro realizado com sucesso",
            icon: "success",
          });
          history.push('/employee');
        }
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
        if (e.response) {
          
          if (e.response.status === 400) {
            swal({
              title: "Ocorreu um no cadastro",
              text: e.response.data,
              icon: "error",
            });
          }else{
            swal({
              title: "Ocorreu um no cadastro",
              icon: "error",
            });
          }

        } else {
          swal({
            title: "Ocorreu um erro",
            text: "Ocorreu um erro no servidor, tente mais tarde",
            icon: "error",
          });
        }
      }
    },
  });

  return (

    <div className={classes.root}>
      <CssBaseline />
      <Navigation title="Funcionários" />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Grid item xs={12} md={12} lg={12}>
            <Paper className={fixedHeightPaper}>
              <Grid container spacing={3}>
                <Typography variant="h5" gutterBottom>
                  Cadastro de funcionário
                </Typography>
                <form onSubmit={formik.handleSubmit} className={classes.form}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        variant="outlined"
                        required
                        id="name"
                        name="name"
                        label="Nome"
                        fullWidth
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        error={formik.touched.name && Boolean(formik.errors.name)}
                        helperText={formik.touched.name && formik.errors.name}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        variant="outlined"
                        required
                        id="phone"
                        name="phone"
                        label="Telefone"
                        fullWidth
                        value={formik.values.phone}
                        onChange={(value) =>
                          formik.setFieldValue('phone', value)
                        }
                        error={formik.touched.phone && Boolean(formik.errors.phone)}
                        helperText={formik.touched.phone && formik.errors.phone}
                        InputProps={{
                          inputComponent: PhoneInput as any,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        variant="outlined"
                        required
                        id="cpf"
                        name="cpf"
                        label="CPF"
                        fullWidth
                        value={formik.values.cpf}
                        onChange={(value) =>
                          formik.setFieldValue('cpf', value)
                        }
                        error={formik.touched.cpf && Boolean(formik.errors.cpf)}
                        helperText={formik.touched.cpf && formik.errors.cpf}
                        InputProps={{
                          inputComponent: CpfInput as any,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        variant="outlined"
                        required
                        id="address"
                        name="address"
                        label="Endereço"
                        fullWidth
                        value={formik.values.address}
                        onChange={formik.handleChange}
                        error={formik.touched.address && Boolean(formik.errors.address)}
                        helperText={formik.touched.address && formik.errors.address}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        variant="outlined"
                        id="registrationNumber"
                        name="registrationNumber"
                        label="Número de matrícula"
                        fullWidth
                        value={formik.values.registrationNumber}
                        onChange={formik.handleChange}
                        error={formik.touched.registrationNumber && Boolean(formik.errors.registrationNumber)}
                        helperText={formik.touched.registrationNumber && formik.errors.registrationNumber}
                      />
                    </Grid>
                    {/*select */}
                    <Grid item xs={12} sm={6}>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel id="pev-select-label">PEV</InputLabel>
                        <Select
                          labelId="pev-select-label"
                          id="idPev"
                          label="PEV"
                          name="idPev"
                          value={formik.values.idPev}
                          error={
                            !!formik.errors.idPev && !!formik.touched.idPev
                          }
                          onChange={formik.handleChange}
                        >
                          {pev.map((pev: PevProps) => (
                            <MenuItem value={pev.id} key={pev.id}>{pev.nome}</MenuItem>
                          ))}
                        </Select>
                        {!!formik.errors.idPev && !!formik.touched.idPev ? (
                                <FormHelperText>
                                    {formik.errors.idPev}
                                </FormHelperText>
                            ) : null}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body1" gutterBottom>
                        Dados de login
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        variant="outlined"
                        id="email"
                        name="email"
                        label="E-mail"
                        fullWidth
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        error={formik.touched.email && Boolean(formik.errors.email)}
                        helperText={formik.touched.email && formik.errors.email}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        variant="outlined"
                        required
                        id="user"
                        name="user"
                        label="Nome de usuário"
                        fullWidth
                        value={formik.values.user}
                        onChange={formik.handleChange}
                        error={formik.touched.user && Boolean(formik.errors.user)}
                        helperText={formik.touched.user && formik.errors.user}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        variant="outlined"
                        required
                        id="password"
                        type="password"
                        name="password"
                        label="Senha"
                        fullWidth
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        error={formik.touched.password && Boolean(formik.errors.password)}
                        helperText={formik.touched.password && formik.errors.password}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        variant="outlined"
                        required
                        id="password_confirmation"
                        type="password"
                        name="password_confirmation"
                        label="Confirmar senha"
                        fullWidth
                        value={formik.values.password_confirmation}
                        onChange={formik.handleChange}
                        error={formik.touched.password_confirmation && Boolean(formik.errors.password_confirmation)}
                        helperText={formik.touched.password_confirmation && formik.errors.password_confirmation}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        startIcon={<Loading show={isLoading} />}
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                      >
                        Cadastrar
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </Grid>
            </Paper>
          </Grid>
        </Container>
      </main>
    </div>
  )
}

export default RegisterEmployee;