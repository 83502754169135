import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import api from '../../../services/api';
import swal from 'sweetalert';

import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';


import Navigation from '../../../components/Navigation';
import Loading from '../../../components/Loading';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: 35,
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',

  },
  fixedHeight: {
    height: 240,
  },
  form: {
    marginTop: 25,
    width: '100%'
  }
}));

const RegisterExchangeItem: React.FC = () => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);

  const history = useHistory();

  const fixedHeightPaper = clsx(classes.paper);

  const validationSchema = yup.object({
    name: yup
      .string()
      .required('Nome obrigatório'),
    unit: yup
      .string()
      .required('Unidade de medida obrigatório'),
    point: yup
      .number()
      .required('Pontos obrigatório')
      .typeError('Digite número de pontos válido'),
    limit: yup
      .string()
      .required('Limite por troca obrigatório')
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      unit: '',
      point: '',
      limit: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        if (!isLoading) {
          setIsLoading(true);

          await api.post('/item', {
            tipo: values.name,
            unidadeDeMedida: values.unit,
            pontos: values.point,
            limite: values.limit
          });

          swal({
            title: "Cadastro realizado com sucesso",
            icon: "success",
          });
          history.push('/exchangeItem');
        }
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
        if (e.response) {
          if (e.response.status === 400) {
            swal({
              title: "Ocorreu um de autenticação",
              text: e.response.data,
              icon: "error",
            });
          } else {
            swal({
              title: "Ocorreu um no cadastro",
              icon: "error",
            });
          }
        } else {
          swal({
            title: "Ocorreu um erro",
            text: "Ocorreu um erro no servidor, tente mais tarde",
            icon: "error",
          });
        }
      }
    },
  });

  return (

    <div className={classes.root}>
      <CssBaseline />
      <Navigation title="Item de troca" />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Grid item xs={12} md={12} lg={12}>
            <Paper className={fixedHeightPaper}>
              <Grid container spacing={3}>
                <Typography variant="h5" gutterBottom>
                  Cadastro de item de troca
                </Typography>
                <form onSubmit={formik.handleSubmit} className={classes.form}>
                  <Grid container spacing={3} >
                    <Grid item xs={12} sm={6}>
                      <TextField
                        variant="outlined"
                        required
                        id="name"
                        name="name"
                        label="Nome"
                        fullWidth
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        error={formik.touched.name && Boolean(formik.errors.name)}
                        helperText={formik.touched.name && formik.errors.name}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel id="unit-select-label">Unidade de Medida</InputLabel>
                        <Select
                          labelId="unit-select-label"
                          id="unit"
                          label="Unidade de medida"
                          name="unit"
                          value={formik.values.unit}
                          onChange={formik.handleChange}
                          error={
                            !!formik.errors.unit && !!formik.touched.unit
                          }
                        >
                          <MenuItem value={1}>Quilograma</MenuItem>
                          <MenuItem value={2}>Unidade</MenuItem>
                        </Select>
                        {!!formik.errors.unit && !!formik.touched.unit ? (
                          <FormHelperText>
                            {formik.errors.unit}
                          </FormHelperText>
                        ) : null}
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <TextField
                        variant="outlined"
                        required
                        id="point"
                        name="point"
                        label="Pontos"
                        fullWidth
                        value={formik.values.point}
                        onChange={formik.handleChange}
                        error={formik.touched.point && Boolean(formik.errors.point)}
                        helperText={formik.touched.point && formik.errors.point}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        variant="outlined"
                        required
                        id="limit"
                        name="limit"
                        label="Limite por troca"
                        fullWidth
                        value={formik.values.limit}
                        onChange={formik.handleChange}
                        error={formik.touched.limit && Boolean(formik.errors.limit)}
                        helperText={formik.touched.limit && formik.errors.limit}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        startIcon={<Loading show={isLoading} />}
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                      >
                        Cadastrar
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </Grid>
            </Paper>
          </Grid>
        </Container>
      </main>
    </div>
  )
}

export default RegisterExchangeItem;