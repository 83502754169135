import React from 'react';
import { Switch } from 'react-router-dom';
    
import Route from './Route';

import SignIn from '../pages/SignIn';
import ForgotPassword from '../pages/ForgotPassword';
import ResetPassword from '../pages/ResetPassword';

import Employee from '../pages/admin/employee/Employee';
import RegisterEmployee from '../pages/admin/employee/RegisterEmployee';
import EditEmployee from '../pages/admin/employee/EditEmployee';

import Partner from '../pages/admin/partner/Partner';
import RegisterPartner from '../pages/admin/partner/RegisterPartner';
import EditPartner from '../pages/admin/partner/EditPartner';

import Pev from '../pages/admin/pev/Pev';
import RegisterPev from '../pages/admin/pev/RegisterPev';
import EditPev from '../pages/admin/pev/EditPev';

import ExchangeItem from '../pages/admin/exchangeItem/ExchangeItem';
import RegisterExchangeItem from '../pages/admin/exchangeItem/RegisterExchangeItem';
import EditExchangeItem from '../pages/admin/exchangeItem/EditExchangeItem';

import ProductValle from '../pages/admin/productValle/ProductValle';
import RegisterProductValle from '../pages/admin/productValle/RegisterProductValle';
import EditProductValle from '../pages/admin/productValle/EditProductValle';


import EditAdmin from '../pages/admin/EditAdmin';
import Dashboard from '../pages/admin/Dashboard';

import HistoricPartner from '../pages/historic/HistoricPartner';
import HistoricEmployee from '../pages/historic/HistoricEmployee';

const Routes: React.FC = () =>(
    <Switch>
        <Route path="/" exact component={SignIn}/>
        <Route path="/editAdmin" component={EditAdmin} isPrivate/>
        <Route path="/dashboard" component={Dashboard} isPrivate/>

        <Route path="/forgotpassword" component={ForgotPassword}/>
        <Route path="/resetpassword" component={ResetPassword}/>

        <Route path="/employee" component={Employee} isPrivate/>
        <Route path="/registerEmployee" component={RegisterEmployee} isPrivate/>
        <Route path="/editEmployee/:id" component={EditEmployee} isPrivate/>
        
        <Route path="/partner" component={Partner} isPrivate/>
        <Route path="/registerPartner" component={RegisterPartner} isPrivate/>
        <Route path="/editPartner/:id" component={EditPartner} isPrivate/>

        <Route path="/pev" component={Pev} isPrivate/>
        <Route path="/registerpev" component={RegisterPev} isPrivate/>
        <Route path="/EditPev/:id" component={EditPev} isPrivate/>

        <Route path="/registerExchangeItem" component={RegisterExchangeItem} isPrivate/>
        <Route path="/exchangeitem" component={ExchangeItem} isPrivate/>
        <Route path="/editExchangeItem/:id" component={EditExchangeItem} isPrivate/>

        <Route path="/productValle" component={ProductValle} isPrivate/>
        <Route path="/registerProductValle" component={RegisterProductValle} isPrivate/>
        <Route path="/editProductValle/:id" component={EditProductValle} isPrivate/>

        <Route path="/historicPartner" component={HistoricPartner} isPrivate/>
        <Route path="/historicEmployee" component={HistoricEmployee} isPrivate/>

    </Switch>
);

export default Routes;