import React, { useEffect, useState } from 'react';
import api from '../../services/api';
import swal from 'sweetalert';

import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Box from '@material-ui/core/Box';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import StorefrontIcon from '@material-ui/icons/Storefront';
import AssignmentIcon from '@material-ui/icons/Assignment';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import {
  ArgumentAxis,
  ValueAxis,
  BarSeries,
  Chart,
  PieSeries,
  Legend
} from '@devexpress/dx-react-chart-material-ui';

import { ValueScale } from '@devexpress/dx-react-chart';

import Navigation from '../../components/Navigation';

interface DataChart {
  pev: string,
  escala: number,
  total: number,
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: 35,
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',

  },
  fixedHeight: {
    height: 240,
  },
  card: {
    minWidth: 230,
    color: "#fff",
    float: 'left',
    margin: '15px',
  },
  titleCard: {
    fontSize: 25,
    textWeight: 'bold',
  },
  cardContent: {
    display: 'flex',
    justifyContent: 'space-between',
    position: 'relative',
  },
  cardIcon: {
    position: 'absolute',
    right: '10px',
    fontSize: '70px',
    color: 'rgba(0,0,0,0.15)'
  },
  cardWrapper: {
    width: '100%',
    display: 'flex',
  },
  bgRed: {
    background: 'linear-gradient(to right, #eb3349, #f45c43)',
  },
  bgBlue: {
    background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
  },
  bgGreen: {
    background: 'linear-gradient(to right, #11998e, #38ef7d)',
  },
  bgYellow: {
    background: 'linear-gradient(to right, #f2994a, #f2c94c)',
  },
  containerChart: {
    marginTop: 50,
    display: 'flex',
    justifyContent: 'center',
  },
  chartInfo:{
    fontSize: '16px',
    paddingBottom: 50,
    textTransform: 'uppercase'
  },
}));

const Dashboard: React.FC = () => {
  const classes = useStyles();
  const [usuarios, setUsuarios] = useState();
  const [parceiros, setParceiros] = useState();
  const [historicoEntrada, setHistoricoEntrada] = useState();
  const [historicoSaida, setHistoricoSaida] = useState();
  const [chartData, setChartData] = useState<DataChart[]>([]);

  useEffect(() => {
    api
      .get("/dash/caixas")
      .then((response) => {
        setUsuarios(response.data.usuarios);
        setParceiros(response.data.parceiros);
        setHistoricoEntrada(response.data.historicoEntrada);
        setHistoricoSaida(response.data.historicoSaida);
      })
      .catch((error) => {
        swal({
          title: "Ocorreu um erro ao buscar informações",
          icon: "error",
        });
      });
  }, []);

  useEffect(() => {
    api
      .get("/dash/graficos")
      .then((response) => {
        setChartData(response.data)
        console.log(chartData);
      })
      .catch((error) => {
        swal({
          title: "Ocorreu um erro ao buscar informações do gráfico",
          icon: "error",
        });
      });
  }, []);

  return (

    <div className={classes.root}>
      <CssBaseline />
      <Navigation title="Dashboard" />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Grid item xs={12} md={12} lg={12}>
              <Grid container spacing={3}>
                <Box className={classes.cardWrapper}>
                  <Card className={`${classes.card} ${classes.bgBlue}`}>
                    <CardContent className={classes.cardContent}>
                      <div>
                        <Typography className={classes.titleCard} gutterBottom>
                          {usuarios}
                        </Typography>
                        <Typography variant="body2" component="h2">
                          Usuários
                        </Typography>
                      </div>
                      <PersonAddIcon className={classes.cardIcon} />
                    </CardContent>
                  </Card>
                  <Card className={`${classes.card} ${classes.bgRed}`}>
                    <CardContent className={classes.cardContent}>
                      <div>
                        <Typography className={classes.titleCard} gutterBottom>
                          {parceiros}
                        </Typography>
                        <Typography variant="body2" component="h2">
                          Parceiros
                          </Typography>
                      </div>
                      <AssignmentIcon className={classes.cardIcon} />
                    </CardContent>
                  </Card>
                  <Card className={`${classes.card} ${classes.bgGreen}`}>
                    <CardContent className={classes.cardContent}>
                      <div>
                        <Typography className={classes.titleCard} gutterBottom>
                          {historicoEntrada}
                        </Typography>
                        <Typography variant="body2" component="h2">
                          Entradas nas PEVs
                          </Typography>
                      </div>
                      <StorefrontIcon className={classes.cardIcon} />
                    </CardContent>
                  </Card>
                  <Card className={`${classes.card} ${classes.bgYellow}`}>
                    <CardContent className={classes.cardContent}>
                      <div>
                        <Typography className={classes.titleCard} gutterBottom>
                          {historicoSaida}
                        </Typography>
                        <Typography variant="body2" component="h2">
                          Número de trocas
                        </Typography>
                      </div>
                      <SwapHorizIcon className={classes.cardIcon} />
                    </CardContent>
                  </Card>
                </Box>
                <Grid container spacing={3} className={classes.containerChart}>
                  {chartData.length === 0 ? 
                        <Typography variant="body2" component="h2" className={classes.chartInfo} align="center">
                          Não há dados para gerar gráficos
                        </Typography> : (
                    <>
                      <Grid item xs={12} sm={6}>
                        <Chart
                          data={chartData}
                          height={350}
                        >
                          <ValueScale name="escala" />

                          <ArgumentAxis />
                          <ValueAxis scaleName="escala" showGrid={false} showLine={true} showTicks={true} />

                          <BarSeries
                            name="pev"
                            valueField="escala"
                            argumentField="pev"
                            scaleName="escala"
                          />
                        </Chart>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Chart
                          data={chartData}
                          height={350}
                        >
                          <PieSeries
                            valueField="total"
                            argumentField="pev"
                          />
                          <Legend />
                        </Chart>
                      </Grid>
                    </>
                  )}
                </Grid>
              </Grid>
          </Grid>
        </Container>
      </main>
    </div>
  )
}

export default Dashboard;