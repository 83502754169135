import React, { createContext, useCallback, useState, useContext } from 'react';
import api from '../services/api';
import swal from 'sweetalert';

interface AuthState {
  usuario: {
    tipoUsuario: number;
  };
}

interface SignInCredentials {
  email: string;
  password: string;
}

interface AuthContextData {
  usuario: object;
  signIn(credenditals: SignInCredentials): Promise<void>;
  signOut(): void;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export const AuthProvider: React.FC = ({ children }) => {

  const [data, setData] = useState<AuthState>(() => {
      const token = localStorage.getItem('@Valle:token');
      const usuario = localStorage.getItem('@Valle:usuario');
      
      if (token && usuario) {          

        return { usuario: JSON.parse(usuario) }
      }    
     
    return {} as AuthState;
  });


  const signIn = useCallback(async ({ email, password }) => {
    try {
      const response = await api.post('auth', {
        user: email,
        senha: password,
      });

      const { token, usuario } = response.data;

      if(usuario.tipoUsuario == 1){
        localStorage.setItem('@Valle:token', token);
        localStorage.setItem('@Valle:usuario', JSON.stringify(usuario));
        setData({ usuario });
      }else{
        swal({
          title: "Ocorreu um de autenticação",
          text: "Usuário não tem permissão de acesso.",
          icon: "error",
        });
      }
      
      
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400) {
          swal({
            title: "Ocorreu um de autenticação",
            text: "Ocorreu um erro ao fazer login, cheque as credenciais.",
            icon: "error",
          });
        }
      } else {
        swal({
          title: "Ocorreu um erro",
          text: "Ocorreu um erro no servidor, tente mais tarde",
          icon: "error",
        });
      }
    }

  }, []);

  const signOut = useCallback(() => {
    localStorage.removeItem('@Valle:token');
    localStorage.removeItem('@Valle:usuario');

    setData({} as AuthState);
  }, [])


  return (
    <AuthContext.Provider value={{ usuario: data.usuario, signIn, signOut }}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}
