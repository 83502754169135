import React, { useState, ChangeEvent } from 'react';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import api from '../../../services/api';
import swal from 'sweetalert';

import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';


import Navigation from '../../../components/Navigation';
import Loading from '../../../components/Loading';
import CnpjInput from '../../../components/CnpjInput';
import PhoneInput from '../../../components/PhoneInput';


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: 35,
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',

  },
  fixedHeight: {
    height: 240,
  },
  form: {
    marginTop: 25,
  },
  input: {
    display: 'none',
  },
  buttonUpload: {
    width: '100%'
  }
}));

const RegisterPartner: React.FC = () => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [isSelect, setIsSelect] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const history = useHistory();

  const fixedHeightPaper = clsx(classes.paper);

  const validationSchema = yup.object({
    name: yup
      .string()
      .required('Nome obrigatório'),
    email: yup
      .string()
      .required('E-mail obrigatório')
      .email('Digite um e-mail válido'),
    cnpj: yup
      .string()
      .required('CNPJ obrigatório')
      .min(14, "Digite um CNPJ")
      .max(14, "Digite um CNPJ"),
    phone: yup
      .string()
      .required('Telefone obrigatório')
      .min(10, "Digite um celular válido")
      .max(11, "Digite um celular válido"),
    user: yup
      .string()
      .required('Usuário obrigatório'),
    address: yup
      .string()
      .required('Endereço obrigatório'),
    password: yup
      .string()
      .required('Senha obrigatória'),
    limiteMin: yup
      .number()
      .typeError('Campo inválido')
      .nullable(),
    tipoTroca: yup
      .string()
      .required('Campo obrigatório'),
    password_confirmation: yup
      .string().oneOf(
        [yup.ref('password'), null],
        'Confirmação incorreta',
      ),
    discount: yup
      .number()
      .typeError('Campo inválido')
      .nullable(),
    preco: yup
      .number()
      .typeError('Campo inválido')
      .nullable(),
    file: yup
      .object().nullable(),
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      cnpj: '',
      phone: '',
      address: '',
      password: '',
      password_confirmation: '',
      responsible: '',
      user: '',
      limiteMin: '',
      tipoTroca: '',
      discount: '',
      file: '',
      preco: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      console.log(values)
      try {
        if (!isLoading) {
          setIsLoading(true);
          const data = new FormData();

          data.append('file', values.file)
          data.append('senha', values.password)
          data.append('confirmarSenha', values.password_confirmation)
          data.append('nome', values.name)
          data.append('user', values.user)
          data.append('tipoUsuario', '3')
          data.append('email', values.email)
          data.append('telefone', values.phone)
          data.append('endereco', values.address)
          data.append('tipoTroca', values.tipoTroca)
          data.append('responsavel', values.responsible)
          data.append('cnpj', values.cnpj)

          if(values.tipoTroca == '1'){
            data.append('LimiteMinimo', '0')
            data.append('valor', '0')
            data.append('preco', '0')
          }else{
            data.append('LimiteMinimo', values.limiteMin)
            data.append('valor', values.discount)
            data.append('preco', values.preco)
          }

          await api.post('/usuario', data);

          swal({
            title: "Cadastro realizado com sucesso",
            icon: "success",
          });
          history.push('/partner');
        }
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
        if (e.response) {
          if (e.response.status === 400) {
            swal({
              title: "Ocorreu um erro",
              text: e.response.data,
              icon: "error",
            });
          } else {
            swal({
              title: "Ocorreu um no cadastro",
              icon: "error",
            });
          }
        } else {
          swal({
            title: "Ocorreu um erro",
            text: "Ocorreu um erro no servidor, tente mais tarde",
            icon: "error",
          });
        }
      }
    },
  });

  function handleSelectFile(val: ChangeEvent<HTMLInputElement>) {
    if (val.target.files) {
      formik.setFieldValue('file', val.target.files[0])
      setIsSelect(true);
    }
  }

  return (

    <div className={classes.root}>
      <CssBaseline />
      <Navigation title="Parceiros" />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Grid item xs={12} md={12} lg={12}>
            <Paper className={fixedHeightPaper}>
              <Grid container spacing={3}>
                <Typography variant="h5" gutterBottom>
                  Cadastro de parceiro
                </Typography>
                <form onSubmit={formik.handleSubmit}>
                  <Grid container spacing={3} className={classes.form}>
                    <Grid item xs={12}>
                      <Typography variant="body1" gutterBottom>
                        Dados do parceiro
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        variant="outlined"
                        required
                        id="name"
                        name="name"
                        label="Nome"
                        fullWidth
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        error={formik.touched.name && Boolean(formik.errors.name)}
                        helperText={formik.touched.name && formik.errors.name}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        variant="outlined"
                        required
                        id="phone"
                        name="phone"
                        label="Telefone"
                        fullWidth
                        value={formik.values.phone}
                        onChange={(value) =>
                          formik.setFieldValue('phone', value)
                        }
                        error={formik.touched.phone && Boolean(formik.errors.phone)}
                        helperText={formik.touched.phone && formik.errors.phone}
                        InputProps={{
                          inputComponent: PhoneInput as any,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        variant="outlined"
                        required
                        id="cnpj"
                        name="cnpj"
                        label="CNPJ"
                        fullWidth
                        value={formik.values.cnpj}
                        onChange={(value) =>
                          formik.setFieldValue('cnpj', value)
                        }
                        error={formik.touched.cnpj && Boolean(formik.errors.cnpj)}
                        helperText={formik.touched.cnpj && formik.errors.cnpj}
                        InputProps={{
                          inputComponent: CnpjInput as any,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        variant="outlined"
                        required
                        id="address"
                        name="address"
                        label="Endereço"
                        fullWidth
                        value={formik.values.address}
                        onChange={formik.handleChange}
                        error={formik.touched.address && Boolean(formik.errors.address)}
                        helperText={formik.touched.address && formik.errors.address}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        variant="outlined"
                        required
                        id="responsible"
                        name="responsible"
                        label="Responsável"
                        fullWidth
                        value={formik.values.responsible}
                        onChange={formik.handleChange}
                        error={formik.touched.responsible && Boolean(formik.errors.responsible)}
                        helperText={formik.touched.responsible && formik.errors.responsible}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <input
                        accept="image/*"
                        className={classes.input}
                        onChange={handleSelectFile}
                        id="file"
                        name="file"
                        multiple
                        type="file"
                      />
                      <label htmlFor="file">
                        <Button
                          variant="contained"
                          color="primary"
                          component="span"
                          startIcon={<CloudUploadIcon />}
                          className={classes.buttonUpload}>
                          {isSelect ? '1 imagem escolhida' : 'Upload Imagem'}
                        </Button>
                      </label>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body1" gutterBottom>
                        Dados de trocas
                      </Typography>
                    </Grid>

                    {/*select */}
                    <Grid item xs={12} sm={6}>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel id="tipoTroca-select-label">Tipo de troca</InputLabel>
                        <Select
                          labelId="tipoTroca-select-label"
                          id="tipoTroca"
                          label="Tipo de troca"
                          name="tipoTroca"
                          value={formik.values.tipoTroca}
                          onChange={formik.handleChange}
                          error={
                            !!formik.errors.tipoTroca && !!formik.touched.tipoTroca
                          }
                        >
                          <MenuItem value={1} onClick={() => setDisabled(true)}>Produtos</MenuItem>
                          <MenuItem value={2} onClick={() => setDisabled(false)}>Porcentagem</MenuItem>
                        </Select>
                        {!!formik.errors.tipoTroca && !!formik.touched.tipoTroca ? (
                          <FormHelperText>
                            {formik.errors.tipoTroca}
                          </FormHelperText>
                        ) : null}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        variant="outlined"
                        required={!disabled}
                        id="discount"
                        name="discount"
                        label="Desconto"
                        disabled={disabled}
                        fullWidth
                        value={formik.values.discount}
                        onChange={formik.handleChange}
                        error={formik.touched.discount && Boolean(formik.errors.discount)}
                        helperText={formik.touched.discount && formik.errors.discount}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        variant="outlined"
                        id="limiteMin"
                        name="limiteMin"
                        required={!disabled}
                        disabled={disabled}
                        label="Valor mínimo de compra"
                        fullWidth
                        onChange={formik.handleChange}
                        error={formik.touched.limiteMin && Boolean(formik.errors.limiteMin)}
                        helperText={formik.touched.limiteMin && formik.errors.limiteMin}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        variant="outlined"
                        required={!disabled}
                        disabled={disabled}
                        id="preco"
                        name="preco"
                        label="Quantidade de pontos"
                        fullWidth
                        onChange={formik.handleChange}
                        error={formik.touched.preco && Boolean(formik.errors.preco)}
                        helperText={formik.touched.preco && formik.errors.preco}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body1" gutterBottom>
                        Dados de login
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        variant="outlined"
                        id="email"
                        name="email"
                        label="E-mail"
                        fullWidth
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        error={formik.touched.email && Boolean(formik.errors.email)}
                        helperText={formik.touched.email && formik.errors.email}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        variant="outlined"
                        required
                        id="user"
                        name="user"
                        label="Nome de usuário"
                        fullWidth
                        value={formik.values.user}
                        onChange={formik.handleChange}
                        error={formik.touched.user && Boolean(formik.errors.user)}
                        helperText={formik.touched.user && formik.errors.user}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        variant="outlined"
                        required
                        id="password"
                        type="password"
                        name="password"
                        label="Senha"
                        fullWidth
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        error={formik.touched.password && Boolean(formik.errors.password)}
                        helperText={formik.touched.password && formik.errors.password}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        variant="outlined"
                        required
                        id="password_confirmation"
                        type="password"
                        name="password_confirmation"
                        label="Confirmar senha"
                        fullWidth
                        value={formik.values.password_confirmation}
                        onChange={formik.handleChange}
                        error={formik.touched.password_confirmation && Boolean(formik.errors.password_confirmation)}
                        helperText={formik.touched.password_confirmation && formik.errors.password_confirmation}
                      />
                    </Grid>


                    <Grid item xs={12}>
                      <Button
                        startIcon={<Loading show={isLoading} />}
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                      >
                        Cadastrar
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </Grid>


            </Paper>
          </Grid>
        </Container>
      </main>
    </div>
  )

}

export default RegisterPartner;