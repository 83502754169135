import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider, createMuiTheme } from '@material-ui/core';
import AppProvider from './hooks';
import Routes from './routes';

function App() {
  const theme = createMuiTheme({
    typography: {
      fontSize: 12,
    },
    palette: {
      primary: {
        main: '#2d7951',
      }
    }
  });
  return (
    <BrowserRouter>
      <AppProvider>
        <ThemeProvider theme={theme}>
          <Routes />
        </ThemeProvider>
      </AppProvider>
    </BrowserRouter>
  );
}

export default App;
