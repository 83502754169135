import React, { useState, useEffect } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import api from '../../../services/api';
import swal from 'sweetalert';

import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import MenuItem from '@material-ui/core/MenuItem';

import Navigation from '../../../components/Navigation';
import Loading from '../../../components/Loading';
import PhoneInput from '../../../components/PhoneInput';
import CpfInput from '../../../components/CpfInput';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: 35,
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',

  },
  fixedHeight: {
    height: 240,
  },
  form: {
    marginTop: 25,
    width: '100%'
  }
}));

interface ItemParams{
    id: string;
}

interface PevProps {
  id: number;
  nome: string;
}

const EditEmployee: React.FC = () => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [LoadingPage, setLoadingPage] = useState(true);
  const [pev, setPev] = useState<PevProps[]>([]);
  const { params } = useRouteMatch<ItemParams>();

  const history = useHistory();

  const fixedHeightPaper = clsx(classes.paper);

  const validationSchema = yup.object({
    nome: yup
    .string()
    .required('Nome obrigatório'),
    email: yup
      .string()
      .email('Digite um e-mail válido'),
    cpf: yup
      .string()
      .required('CPF obrigatório')
      .min(11, "Digite um CPF válido")
      .max(11, "Digite um CPF válido"),
    telefone: yup
      .string()
      .required('Telefone obrigatório')
      .min(10, "Digite um celular válido")
      .max(11, "Digite um celular válido"),
    user: yup
      .string()
      .required('Endereço obrigatório'),
    endereco: yup
      .string()
      .required('Endereço obrigatório'),
    senha: yup
      .string(),
    senhaConfirmacao: yup
      .string()
      .when('senha', {
        is: true,
        then: yup.string().required('Campo obrigatório'),
        otherwise: yup.string(),
      })
      .oneOf([yup.ref('senha')], 'Confirmação incorreta'),
    registrationNumber: yup
      .string()
  });

  const formik = useFormik({
    initialValues: {
      senha: '',
      confirmarSenha: '',
      nome: '',
      user: '',
      email: '',
      telefone: '',
      endereco: '',
      idPev: '',
      matricula: '',
      cpf: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        if (!isLoading) {
          setIsLoading(true);

          await api.put(`/usuario/${params.id}`, {
            senha: values.senha,
            confirmarSenha: values.confirmarSenha,
            nome: values.nome,
            tipoUsuario: '4',
            user: values.user,
            email: values.email,
            telefone: values.telefone,
            endereco: values.endereco,
            idPev: values.idPev,
            matricula: values.matricula,
            cnpj: null,
            cpf: values.cpf,
          });

          swal({
            title: "Atualização realizado com sucesso",
            icon: "success",
          });
          history.push('/employee');
        }
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
        if (e.response) {
          if (e.response.status === 400) {
            swal({
              title: "Ocorreu um de autenticação",
              text: e.response.data,
              icon: "error",
            });
          }
        } else {
          swal({
            title: "Ocorreu um erro",
            text: "Ocorreu um erro no servidor, tente mais tarde",
            icon: "error",
          });
        }
      }
    },
  });

  
  useEffect(() => {
    api
      .get("/pev")
      .then((response) => {
        setPev(response.data);
      })
      .catch((error) => {
        swal({
          title: "Ocorreu um erro ao buscar as PEVs",
          icon: "error",
        });
      });
  }, []);

  useEffect(() => {
    api
      .get(`/usuarioEspecifico/${params.id}`)
      .then((response) => {
        formik.setValues(response.data);
        setLoadingPage(false)
      })  
      .catch((error) => {
        console.log(error)
        swal({
          title: "Ocorreu um erro ao buscar dados",
          icon: "error",
        });
      });
      
  }, []); 

  if (LoadingPage) {
    return null
  }

  return (

    <div className={classes.root}>
      <CssBaseline />
      <Navigation title="funcionário" />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Grid item xs={12} md={12} lg={12}>
            <Paper className={fixedHeightPaper}>
              <Grid container spacing={3}>
                <Typography variant="h5" gutterBottom>
                  Atualização funcionário
                </Typography>
                <form onSubmit={formik.handleSubmit} className={classes.form}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        variant="outlined"
                        required
                        id="nome"
                        name="nome"
                        label="Nome"
                        fullWidth
                        value={formik.values.nome}
                        onChange={formik.handleChange}
                        error={formik.touched.nome && Boolean(formik.errors.nome)}
                        helperText={formik.touched.nome && formik.errors.nome}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        variant="outlined"
                        required
                        id="telefone"
                        name="telefone"
                        label="Telefone"
                        fullWidth
                        value={formik.values.telefone}
                        onChange={(value) => formik.setFieldValue('telefone', value)}
                        error={formik.touched.telefone && Boolean(formik.errors.telefone)}
                        helperText={formik.touched.telefone && formik.errors.telefone}
                        InputProps={{
                          inputComponent: PhoneInput as any
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        variant="outlined"
                        required
                        id="cpf"
                        name="cpf"
                        label="CPF"
                        fullWidth
                        value={formik.values.cpf}
                        onChange={(value) =>
                          formik.setFieldValue('cpf', value)
                        }
                        error={formik.touched.cpf && Boolean(formik.errors.cpf)}
                        helperText={formik.touched.cpf && formik.errors.cpf}
                        InputProps={{
                          inputComponent: CpfInput as any
                        }} 
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        variant="outlined"
                        required
                        id="endereco"
                        name="endereco"
                        label="Endereço"
                        fullWidth
                        value={formik.values.endereco}
                        onChange={formik.handleChange}
                        error={formik.touched.endereco && Boolean(formik.errors.endereco)}
                        helperText={formik.touched.endereco && formik.errors.endereco}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        variant="outlined"
                        required
                        id="matricula"
                        name="matricula"
                        label="Número de matrícula"
                        fullWidth
                        value={formik.values.matricula}
                        onChange={formik.handleChange}
                        error={formik.touched.matricula && Boolean(formik.errors.matricula)}
                        helperText={formik.touched.matricula && formik.errors.matricula}
                      />
                    </Grid>
                    {/*select */}
                    <Grid item xs={12} sm={6}>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel id="pev-select-label">PEV</InputLabel>
                        <Select
                          labelId="pev-select-label"
                          id="idPev"
                          label="PEV"
                          name="idPev"
                          value={formik.values.idPev}
                          onChange={formik.handleChange}
                        >
                          {pev.map((pev: PevProps) => (
                            <MenuItem value={pev.id} key={pev.id}>{pev.nome}</MenuItem>
                          ))}
                        </Select>
                        <FormHelperText>{formik.touched.idPev && Boolean(formik.errors.idPev)}</FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body1" gutterBottom>
                        Dados de login
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        variant="outlined"
                        id="email"
                        name="email"
                        label="E-mail"
                        fullWidth
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        error={formik.touched.email && Boolean(formik.errors.email)}
                        helperText={formik.touched.email && formik.errors.email}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        variant="outlined"
                        required
                        id="user"
                        name="user"
                        label="Nome de usuário"
                        fullWidth
                        value={formik.values.user}
                        onChange={formik.handleChange}
                        error={formik.touched.user && Boolean(formik.errors.user)}
                        helperText={formik.touched.user && formik.errors.user}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        variant="outlined"
                        id="senha"
                        type="password"
                        name="senha"
                        label="Senha"
                        fullWidth
                        value={formik.values.senha}
                        onChange={formik.handleChange}
                        error={formik.touched.senha && Boolean(formik.errors.senha)}
                        helperText={formik.touched.senha && formik.errors.senha}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        variant="outlined"
                        id="confirmarSenha"
                        type="password"
                        name="confirmarSenha"
                        label="Confirmar senha"
                        fullWidth
                        value={formik.values.confirmarSenha}
                        onChange={formik.handleChange}
                        error={formik.touched.confirmarSenha && Boolean(formik.errors.confirmarSenha)}
                        helperText={formik.touched.confirmarSenha && formik.errors.confirmarSenha}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        startIcon={<Loading show={isLoading} />}
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                      >
                        Atualizar
                      </Button>
                    </Grid>
                  </Grid>
                  </form>
              </Grid>
            </Paper>
          </Grid>
        </Container>
      </main>
    </div>
  )
}

export default EditEmployee;