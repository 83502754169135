import React from 'react'
import PropTypes from 'prop-types'
import MaskedInput, { conformToMask } from 'react-text-mask';

export default function PhoneInput({ inputRef, onChange, value, ...props }) {
    const [inputValue, setInputValue] = React.useState(value)

    return (
        <MaskedInput
            {...props}
            value={inputValue}
            onChange={function (value) {
                setInputValue(value.target.value)
                const { conformedValue } = conformToMask(
                    value.target.value, [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/], { guide: false }
                )
                onChange(conformedValue)
            }}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={function (val) {
                return val.length < 15
                    ? ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
                    : ['(', /\d/, /\d/, ')', ' ', /\d/, ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
            }}
            guide={false}
        />
    )
}

PhoneInput.propTypes = {
    inputRef: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired
};