import axios from 'axios';


const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

api.interceptors.request.use(async config => {
  const token = localStorage.getItem('@Valle:token');
  config.headers.Authorization = `Bearer ${token}`;

  return config;
});

export default api;