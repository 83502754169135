import React, { useState, useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';
import swal from 'sweetalert';
import api from '../../../services/api';

import { createStyles, lighten, makeStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import CloseIcon from '@material-ui/icons/Close';
import TablePagination from '@material-ui/core/TablePagination';

import Navigation from '../../../components/Navigation';

interface EmployeeProps {
  id: number;
  nome: string;
  telefone: string;
  pevUsuario: {
    pev:{
      nome: string;
    }
  }
  matricula: string;
}

interface HeadCell {
  disablePadding: boolean;
  id: string;
  label: string;
  numeric: boolean;
}

const headCells: HeadCell[] = [
  { id: 'nome', numeric: false, disablePadding: true, label: 'Nome' },
  { id: 'telefone', numeric: false, disablePadding: true, label: 'Telefone' },
  { id: 'pev', numeric: false, disablePadding: true, label: 'PEV' },
  { id: 'matricula', numeric: false, disablePadding: true, label: 'Matrícula' },
  { id: 'acoes', numeric: false, disablePadding: false, label: 'Ações' },
];

{/*HEAD TABELA */}
function EnhancedTableHead() {
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const useToolbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    highlight:
      theme.palette.type === 'light'
        ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
        : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
    title: {
      flex: '1 1 100%',
    },
    input: {
      width: '65%',
    },
    toolbar: {
      marginBottom: '30px',
      paddingTop: '30px',
      justifyContent: 'space-between',
    },
    containerButton: {
      display: 'flex',
      justifyContent: 'flex-end',
    }
  }),
);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    button: {
      minWidth: 0,
      margin: theme.spacing(0.5)
    }
  }),
);

function EnhancedTable() {
  const classes = useStyles();
  const classesToolbar = useToolbarStyles();

  const [employee, setEmployee] = useState<EmployeeProps[]>([]);

  const history = useHistory();
  
  const routeChange = () => {
    let path = `/registerEmployee`;
    history.push(path);
  }

  
  {/* PAGINACAO */}  
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDelete = (id:Number) => {
    api
      .delete(`/usuario/${id}`)
      .then((response) => {
        swal({
          title: "Funcionário deletado com sucesso",
          icon: "success",
        });

        {/* atualizando GRID*/}
        api
        .get("/usuario/4")
        .then((response) => {
          setEmployee(response.data);
        })
        .catch((error) => {
          swal({
            title: "Ocorreu um erro ao buscar funcionários",
            icon: "error",
          });
        });
      })
      .catch((error) => {
        swal({
          title: "Ocorreu ao deletar funcionários",
          text: error.response.data,
          icon: "error",
        });
      });   
  };

  const handleFilter = (e: React.ChangeEvent<HTMLInputElement>) =>{
    api
      .get(`/usuario/4/?nome=${e.target.value}`)
      .then((response) => {
        setEmployee(response.data);
      })
      .catch((error) => {
        swal({
          title: "Ocorreu um erro ao buscar funcionários",
          icon: "error",
        });
      });
  }   

  const employeeAfterPagingAndSorting = () =>{
    return employee.slice(page*rowsPerPage, (page+1)*rowsPerPage);
  }

  useEffect(() => {
    api
      .get("/usuario/4")
      .then((response) => {
        setEmployee(response.data);
      })
      .catch((error) => {
        swal({
          title: "Ocorreu um erro ao buscar funcionários",
          icon: "error",
        });
      });
  }, []);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
      <Toolbar className={classesToolbar.toolbar}>
          <TextField
            className={classesToolbar.input}
            variant="outlined"
            label="Pesquisar funcionarios"
            onChange={handleFilter} 
            InputProps={{
              startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
            }}
          />
          <Button
            variant="outlined" 
            color="primary"
            size="large"
            onClick={routeChange}
            className={classesToolbar.containerButton}
          >
            Cadastrar funcionário
          </Button>
        </Toolbar>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={'medium'}
            aria-label="enhanced table"
          >
            <EnhancedTableHead/>
            <TableBody>
              {employeeAfterPagingAndSorting().map((employee: EmployeeProps) => (
                <TableRow
                  hover
                  tabIndex={-1}
                  key={employee.id}
                >
                  <TableCell>{employee.nome}</TableCell>
                  <TableCell>{employee.telefone}</TableCell>
                  <TableCell>{employee.pevUsuario.pev.nome}</TableCell>
                  <TableCell>{employee.matricula}</TableCell>
                  <TableCell>
                    <Link to={`/editEmployee/${employee.id}`} className={classes.button} > 
                      <EditOutlinedIcon fontSize="small"/>
                    </Link>
                    <Button onClick={() => { handleDelete(employee.id) }} color="secondary" className={classes.button}>
                      <CloseIcon fontSize="small"/>
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
				<TablePagination
					rowsPerPageOptions={[5, 10, 25]}
					component="div"
					count={employee.length}
					rowsPerPage={rowsPerPage}
					page={page}
					onChangePage={handleChangePage}
					onChangeRowsPerPage={handleChangeRowsPerPage}
          labelDisplayedRows={
            ({ from, to, count }) => {
              return '' + from + '-' + to + ' de ' + count
            }
          }
          labelRowsPerPage={"Linhas por página"}
				/>
      </Paper>
    </div>
  );
}

const useMainStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      height: '100vh',
      overflow: 'auto',
    },
    container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
    paper: {
      padding: theme.spacing(2),
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column',
    },
    fixedHeight: {
      height: 240,
    },

  }),
);


export default function Employee() {
  const classes = useMainStyles();
  return (
    <div className={classes.root}>
      <CssBaseline />
      <Navigation title="Funcionarios" />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          {/*TABELA*/}
          <EnhancedTable />
        </Container>
      </main>
    </div>
  );
}
