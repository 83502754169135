import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import api from '../../services/api';
import swal from 'sweetalert';

import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';

import Navigation from '../../components/Navigation';
import Loading from '../../components/Loading';
import PhoneInput from '../../components/PhoneInput';
import CpfInput from '../../components/CpfInput';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: 35,
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',

  },
  fixedHeight: {
    height: 240,
  },
  form: {
    marginTop: 25,
    width: '100%'
  }
}));

const EditAdmin: React.FC = () => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [LoadingPage, setLoadingPage] = useState(true);

  const history = useHistory();

  const fixedHeightPaper = clsx(classes.paper);

  const validationSchema = yup.object({
    nome: yup
      .string()
      .required('Nome obrigatório'),
    email: yup
      .string()
      .required('E-mail obrigatório')
      .email('Digite um e-mail válido'),
    cpf: yup
      .string()
      .nullable(),
    telefone: yup
      .string()
      .min(11, "Digite um celular válido")
      .max(11, "Digite um celular válido"),
    user: yup
      .string()
      .required('Usuário obrigatório'),
    endereco: yup
      .string()
      .nullable(),
    senha: yup
      .string(),
    confirmarSenha: yup
      .string().oneOf(
        [yup.ref('senha'), null],
        'Confirmação incorreta',
      ),
  });

  const formik = useFormik({
    initialValues: {
      nome: '',
      email: '',
      cpf: '',
      telefone: '',
      endereco: '',
      senha: '',
      confirmarSenha: '',
      user: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        if (!isLoading) {
          setIsLoading(true);

          await api.put('/usuario/0', {
            senha: values.senha,
            confirmarSenha: values.confirmarSenha,
            nome: values.nome,
            tipoUsuario: '1',
            user: values.user,
            email: values.email,
            telefone: values.telefone,
            endereco: values.endereco,
            cnpj: null,
            cpf: values.cpf,
          });

          swal({
            title: "Atualização realizada com sucesso",
            icon: "success",
          });
          history.push('/dashboard');
        }
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
        if (e.response) {
          if (e.response.status === 400) {
            swal({
              title: "Ocorreu um erro",
              text: e.response.data,
              icon: "error",
            });
          }
        } else {
          swal({
            title: "Ocorreu um erro",
            text: "Ocorreu um erro no servidor, tente mais tarde",
            icon: "error",
          });
        }
      }
    },
  });

  useEffect(() => {
    api
      .get('/perfil')
      .then((response) => {
        formik.setValues(response.data);
        setLoadingPage(false)
      })
      .catch((error) => {
        console.log(error)
        swal({
          title: "Ocorreu um erro ao buscar dados",
          icon: "error",
        });
      });
  }, []);

  if (LoadingPage) {
    return null
}

  return (

    <div className={classes.root}>
      <CssBaseline />
      <Navigation title="Administrador" />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Grid item xs={12} md={12} lg={12}>
            <Paper className={fixedHeightPaper}>
              <Grid container spacing={3}>
                <Typography variant="h5" gutterBottom>
                  Atualizar dados
                </Typography>
                <form onSubmit={formik.handleSubmit} className={classes.form}>
                  <Grid container spacing={3} className={classes.form}>
                    <Grid item xs={12}>
                      <Typography variant="body1" gutterBottom>
                        Dados do parceiro
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        variant="outlined"
                        required
                        id="nome"
                        name="nome"
                        label="Nome"
                        fullWidth
                        value={formik.values.nome}
                        onChange={formik.handleChange}
                        error={formik.touched.nome && Boolean(formik.errors.nome)}
                        helperText={formik.touched.nome && formik.errors.nome}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        error={formik.touched.telefone && Boolean(formik.errors.telefone)}
                        helperText={formik.touched.telefone && formik.errors.telefone}
                        variant="outlined"
                        onChange={(value) => formik.setFieldValue('telefone', value)}
                        id="telefone"
                        name="telefone"
                        label="Telefone"
                        fullWidth
                        value={formik.values.telefone}
                        InputProps={{
                          inputComponent: PhoneInput as any
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        variant="outlined"
                        id="cpf"
                        name="cpf"
                        label="CPF"
                        fullWidth
                        value={formik.values.cpf}
                        onChange={(value) => formik.setFieldValue('cpf', value)}
                        error={formik.touched.cpf && Boolean(formik.errors.cpf)}
                        helperText={formik.touched.cpf && formik.errors.cpf}
                        InputProps={{
                          inputComponent: CpfInput as any
                        }}                       
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        variant="outlined"
                        id="endereco"
                        name="endereco"
                        label="Endereço"
                        fullWidth
                        value={formik.values.endereco}
                        onChange={formik.handleChange}
                        error={formik.touched.endereco && Boolean(formik.errors.endereco)}
                        helperText={formik.touched.endereco && formik.errors.endereco}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body1" gutterBottom>
                        Dados de login
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        variant="outlined"
                        id="email"
                        name="email"
                        label="E-mail"
                        fullWidth
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        error={formik.touched.email && Boolean(formik.errors.email)}
                        helperText={formik.touched.email && formik.errors.email}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        variant="outlined"
                        required
                        id="user"
                        name="user"
                        label="Nome de usuário"
                        fullWidth
                        value={formik.values.user}
                        onChange={formik.handleChange}
                        error={formik.touched.user && Boolean(formik.errors.user)}
                        helperText={formik.touched.user && formik.errors.user}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        variant="outlined"
                        id="senha"
                        type="password"
                        name="senha"
                        label="Senha"
                        fullWidth
                        value={formik.values.senha}
                        onChange={formik.handleChange}
                        error={formik.touched.senha && Boolean(formik.errors.senha)}
                        helperText={formik.touched.senha && formik.errors.senha}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        variant="outlined"
                        id="confirmarSenha"
                        type="password"
                        name="confirmarSenha"
                        label="Confirmar senha"
                        fullWidth
                        value={formik.values.confirmarSenha}
                        onChange={formik.handleChange}
                        error={formik.touched.confirmarSenha && Boolean(formik.errors.confirmarSenha)}
                        helperText={formik.touched.confirmarSenha && formik.errors.confirmarSenha}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        startIcon={<Loading show={isLoading} />}
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                      >
                        Atualizar
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </Grid>
            </Paper>
          </Grid>
        </Container>
      </main>
    </div>
  )
}

export default EditAdmin;