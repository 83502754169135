import React, { useState, useEffect } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import api from '../../../services/api';
import swal from 'sweetalert';

import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';

import Navigation from '../../../components/Navigation';
import Loading from '../../../components/Loading';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: 35,
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',

  },
  fixedHeight: {
    height: 240,
  },
  form: {
    marginTop: 25,
    width: '100%'
  }
}));

interface ProductValleParams{
  id: string;
}
const EditProductValle: React.FC = () => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const { params } = useRouteMatch<ProductValleParams>();


  const history = useHistory();

  const fixedHeightPaper = clsx(classes.paper);

  const validationSchema = yup.object({
    nome: yup
      .string()
      .required('Nome obrigatório'),
    pontos: yup
      .number()
      .required('Pontos obrigatório')
      .typeError('Digite número de pontos válido'),
  });

  const formik = useFormik({
    initialValues: {
      nome: '',
      pontos: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        if (!isLoading) {
          setIsLoading(true);

          await api.put(`/itemVale/${params.id}`, {
            nome: values.nome,
            pontos: values.pontos,
          });

          swal({
            title: "Cadastro realizado com sucesso",
            icon: "success",
          });
          history.push('/productValle');
        }
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
        console.log(e)
        if (e.response) {
          if (e.response.status === 400) {
            swal({
              title: "Ocorreu um de autenticação",
              text: e.response.data,
              icon: "error",
            });
          }
        } else {
          swal({
            title: "Ocorreu um erro",
            text: "Ocorreu um erro no servidor, tente mais tarde",
            icon: "error",
          });
        }
      }
    },
  });

  useEffect(() => {
    api
      .get(`/itemVale/${params.id}`)
      .then((response) => {
        formik.setValues(response.data);
      })  
      .catch((error) => {
        console.log(error)
        swal({
          title: "Ocorreu um erro ao buscar dados",
          icon: "error",
        });
      });
      
  }, []); 

  return (

    <div className={classes.root}>
      <CssBaseline />
      <Navigation title="Produtos Valle" />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Grid item xs={12} md={12} lg={12}>
            <Paper className={fixedHeightPaper}>
              <Grid container spacing={3}>
                <Typography variant="h5" gutterBottom>
                  Atualizar produto valle
                </Typography>
                <form onSubmit={formik.handleSubmit} className={classes.form}>
                  <Grid container spacing={3} >
                    <Grid item xs={12} sm={6}>
                      <TextField
                        variant="outlined"
                        required
                        id="nome"
                        name="nome"
                        label="Nome"
                        fullWidth
                        value={formik.values.nome}
                        onChange={formik.handleChange}
                        error={formik.touched.nome && Boolean(formik.errors.nome)}
                        helperText={formik.touched.nome && formik.errors.nome}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        variant="outlined"
                        required
                        id="pontos"
                        name="pontos"
                        label="Pontos"
                        fullWidth
                        value={formik.values.pontos}
                        onChange={formik.handleChange}
                        error={formik.touched.pontos && Boolean(formik.errors.pontos)}
                        helperText={formik.touched.pontos && formik.errors.pontos}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        startIcon={<Loading show={isLoading} />}
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                      >
                        Atualizar
                      </Button>
                    </Grid>
                  </Grid>
                  </form>
              </Grid>
            </Paper>
          </Grid>
        </Container>
      </main>
    </div>
  )
}

export default EditProductValle;