import React, { useState, useEffect } from 'react';
import swal from 'sweetalert';
import api from '../../services/api';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import TablePagination from '@material-ui/core/TablePagination';

import Navigation from '../../components/Navigation';

interface HistoricPartnerProps {
  id: number;
  pontos: string;
  historico: {
    data: Date;
    usuario: {
      nome: string;
    }
  }
  municipe: {
    nome: string;
  }
}

interface HeadCell {
  disablePadding: boolean;
  id: string;
  label: string;
  numeric: boolean;
}

const headCells: HeadCell[] = [
  { id: 'parceiro', numeric: false, disablePadding: false, label: 'Parceiro' },
  { id: 'municipe', numeric: false, disablePadding: false, label: 'Municipe' },
  { id: 'pontos', numeric: true, disablePadding: true, label: 'Pontos' },
  { id: 'data', numeric: true, disablePadding: false, label: 'Data' },
];

function EnhancedTableHead() {
  return (
    <TableHead>
      <button className="" onClick={buildReportExcel} style={{
        color: 'white',
        borderRadius: '5px',
        padding: '10px',
        border: 'none',
        margin: '10px',
        backgroundColor: '#023B1B'
      }}>Exportar Excel</button>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
  }),
);


function buildReportExcel() {
  api
    .get("/excelhistoricoParceiro")
    .then((response) => {
      console.log(response.data);
      DownloadReportExcel();
    })
    .catch((error) => {
      swal({
        title: "Ocorreu um erro ao buscar histórico",
        icon: "error",
      });
    });
}


function DownloadReportExcel() { 
  window.open(process.env.REACT_APP_API_URL + "download/historicoparceiro",'_blank');
 }


function EnhancedTable() {
  const classes = useStyles();

  {/* PAGINACAO */ }
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  const [historicPartner, setHistoricPartner] = useState<HistoricPartnerProps[]>([]);

  const historicPartnerAfterPagingAndSorting = () => {
    return historicPartner.slice(page * rowsPerPage, (page + 1) * rowsPerPage);
  }

  useEffect(() => {
    api
      .get("/historicoParceiro")
      .then((response) => {
        setHistoricPartner(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        swal({
          title: "Ocorreu um erro ao buscar histórico",
          icon: "error",
        });
      });
  }, []);



  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={'medium'}
            aria-label="enhanced table"
          >
            <EnhancedTableHead />
            <TableBody>
              {historicPartnerAfterPagingAndSorting().map((historicPartner: HistoricPartnerProps) => (
                <TableRow
                  hover
                  tabIndex={-1}
                  key={historicPartner.id}
                >

                  <TableCell>{historicPartner.historico.usuario.nome}</TableCell>
                  <TableCell>{historicPartner.municipe.nome}</TableCell>
                  <TableCell>{historicPartner.pontos}</TableCell>
                  <TableCell>{historicPartner.historico.data}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={historicPartner.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          labelDisplayedRows={
            ({ from, to, count }) => {
              return '' + from + '-' + to + ' de ' + count
            }
          }
          labelRowsPerPage={"Linhas por página"}
        />
      </Paper>
    </div>
  );
}

const useMainStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      height: '100vh',
      overflow: 'auto',
    },
    container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
    paper: {
      padding: theme.spacing(2),
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column',
    },
    fixedHeight: {
      height: 240,
    },

  }),
);

export default function HistoricPartner() {
  const classes = useMainStyles();
  return (
    <div className={classes.root}>
      <CssBaseline />
      <Navigation title="Histórico parceiros" />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          {/*TABELA*/}
          <EnhancedTable />
        </Container>
      </main>
    </div>
  );
}
