import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress';
import PropType from 'prop-types';

export default function Loading({ show, color = "#fff" }) {
    if (show) {
        return (
            <CircularProgress size={15} style={{ color }} />
        )
    }

    return null
}

Loading.propTypes = {
    show: PropType.bool.isRequired,
    color: PropType.string
}